// Code generated by Slice Machine. DO NOT EDIT.

import dynamic from "next/dynamic";

export const components = {
  calendly_booking: dynamic(() => import("./CalendlyBooking")),
  contact_details: dynamic(() => import("./ContactDetails")),
  contact_form: dynamic(() => import("./ContactForm")),
  course_content: dynamic(() => import("./CourseContent")),
  cta_bar: dynamic(() => import("./CtaBar")),
  faqs: dynamic(() => import("./Faqs")),
  fullwidth_split_section: dynamic(() => import("./FullwidthSplitSection")),
  hero: dynamic(() => import("./Hero")),
  hero_parallax: dynamic(() => import("./HeroParallax")),
  image: dynamic(() => import("./Image")),
  image_cards: dynamic(() => import("./ImageCards")),
  page_intro_text: dynamic(() => import("./PageIntroText")),
  parallax_cta: dynamic(() => import("./ParallaxCta")),
  quote: dynamic(() => import("./Quote")),
  section_intro: dynamic(() => import("./SectionIntro")),
  split_section: dynamic(() => import("./SplitSection")),
  steps_grid: dynamic(() => import("./StepsGrid")),
  text: dynamic(() => import("./Text")),
  text_with_image: dynamic(() => import("./TextWithImage")),
  video_grid: dynamic(() => import("./VideoGrid")),
};
