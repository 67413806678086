import React, { useContext, useEffect } from "react";
import { Box, Button } from "@chakra-ui/react";
import * as prismic from "@prismicio/client";
import { PrismicText } from "@prismicio/react";
import { PrismicNextLink } from "@prismicio/next";
import { Image } from "@chakra-ui/react";
import styles from "./Header.module.css";
import Link from "next/link";
import MobileMenu from "@/components/MobileMenu/MobileMenu";
import { AppConfig } from "@/utils/AppConfig";
import { useRouter } from "next/router";
import {
  Link as ScrollLink,
  animateScroll as scroll,
  scroller,
} from "react-scroll";

export function Header({ navigation, settings, headerStyle, logoStyle }) {
  const router = useRouter();
  const { asPath, pathname } = router;

  const styleMap = {
    dark: styles.dark,
    transparent: styles.transparent,
    light: styles.light,
  };

  const handleClick = () => {
    // Check if the current path is the homepage
    if (router.pathname === "/") {
      // Use react-scroll to scroll to the specific component
      scroller.scrollTo("calendlyBooking", {
        duration: 500,
        smooth: "easeInOutQuint",
      });
    } else {
      // Redirect to the homepage and then scroll to the specific component
      router.push("/#calendlyBooking");
    }
  };

  // Get the class name based on the headerStyle value or use a default value if it doesn't match any key in the styleMap
  const headerClassName = styleMap[headerStyle] || styles.default; // Replace 'default' with your default class name

  return (
    <Box role="navigation">
      <MobileMenu logoStyle={logoStyle} navigation={navigation} />
      <Box className={[headerClassName, styles.headerWrapper].join(" ")}>
        <Box className={[styles.header, "wideContainer"].join(" ")}>
          <Link href="/">
            {logoStyle === "dark" ? (
              <Image
                className={styles.logo}
                src="/eca-glasgow-logo-dark.png"
                alt={`${AppConfig.site_name} Logo`}
              />
            ) : (
              <Image
                className={styles.logo}
                src="/eca-glasgow-logo.png"
                alt={`${AppConfig.site_name} Logo`}
              />
            )}
          </Link>

          <Box className={styles.navContainer}>
            <ul className={styles.navList}>
              {navigation.data?.links.map((item) => (
                <li
                  key={prismic.asText(item.label)}
                  className={[
                    styles.navItem,
                    item.ctaStyle ? "button" : "",
                    asPath === prismic.asLink(item.link)
                      ? styles.linkActive
                      : "",
                  ].join(" ")}
                >
                  <PrismicNextLink field={item.link}>
                    <PrismicText field={item.label} />
                  </PrismicNextLink>
                </li>
              ))}

              <li key="calendlyScroll">
                <button onClick={handleClick} className="button">
                  Arrange a Call
                </button>
              </li>
            </ul>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
