import React, { useState, useContext } from "react";
import styles from "./MobileMenu.module.css";
import Link from "next/link";
import { Box, Image } from "@chakra-ui/react";
import { PrismicNextLink } from "@prismicio/next";
import { PrismicText } from "@prismicio/react";
import * as prismic from "@prismicio/client";
import { AppConfig } from "@/utils/AppConfig";
import {
  Link as ScrollLink,
  animateScroll as scroll,
  scroller,
} from "react-scroll";
import { useRouter } from "next/router";

const MobileMenu = ({ navigation, logoStyle }) => {
  const router = useRouter();
  const { asPath, pathname } = router;

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const styleMap = {
    dark: styles.dark,
    transparent: styles.transparent,
    light: styles.light,
  };

  const { mobile_header_style, mobile_logo_style } = navigation.data;

  // Get the class name based on the
  const headerClassName = styleMap[mobile_header_style] || styles.default;

  const handleClick = () => {
    // Check if the current path is the homepage
    if (router.pathname === "/") {
      // Use react-scroll to scroll to the specific component
      scroller.scrollTo("calendlyBooking", {
        duration: 500,
        smooth: "easeInOutQuint",
      });
    } else {
      // Redirect to the homepage and then scroll to the specific component
      router.push("/#calendlyBooking");
    }
    toggleMenu();
  };

  return (
    <div
      className={[
        headerClassName,
        styles.mobileMenu,
        isOpen ? styles.mobileMenuOpen : "",
      ].join(" ")}
    >
      <Box className={styles.menuBar}>
        <Link href="/">
          {/* {logoStyle === "dark" ? <Image className={styles.logo} src="/logo-dark.png" alt="" /> : <Image className={styles.logo} src="/logo-light.png" alt={`${AppConfig.site_name} Logo`} />} */}
          {mobile_logo_style === "dark" ? (
            <Image
              className={styles.logo}
              src="/eca-glasgow-logo-dark.png"
              alt={`${AppConfig.site_name} Logo`}
            />
          ) : (
            <Image
              className={styles.logo}
              src="/eca-glasgow-logo.png"
              alt={`${AppConfig.site_name} Logo`}
            />
          )}
        </Link>
        <div className={styles.menuIcon} onClick={toggleMenu}>
          <div className={[styles.bar, styles.bar1].join(" ")} />
          <div className={[styles.bar, styles.bar2].join(" ")} />
          <div className={[styles.bar, styles.bar3].join(" ")} />
        </div>
      </Box>
      <div
        className={[
          styles.menuContent,
          isOpen ? styles.menuContentOpen : "",
        ].join(" ")}
      >
        <Box className={styles.menuInner}>
          <ul className={styles.navList}>
            {navigation.data?.links.map((item) => (
              <PrismicNextLink
                field={item.link}
                key={`${prismic.asText(item.label)}-mobile`}
                onClick={toggleMenu}
              >
                <li
                  className={[
                    styles.navItem,
                    item.ctaStyle ? "button" : null,
                  ].join(" ")}
                >
                  <PrismicText field={item.label} />
                </li>
              </PrismicNextLink>
            ))}
            <li key="modalCta-mobile">
              <button
                className={[styles.cta, "button"].join(" ")}
                onClick={handleClick}
              >
                Arrange a Call
              </button>
            </li>
          </ul>
        </Box>
      </div>
    </div>
  );
};

export default MobileMenu;
