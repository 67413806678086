import React from "react";
import * as prismic from "@prismicio/client";
import styles from "./PageHeading.module.css";

import { Box, Heading } from "@chakra-ui/react";

interface IPageHeadingProps {
  title: prismic.RichTextField;
}

const PageHeading = ({ title }: IPageHeadingProps) => {
  return (
    <Box className={styles.pageHeadingWrapper}>
      <Heading as="h1" textStyle="h1" className={styles.pageHeading}>
        {prismic.asText(title)}
      </Heading>
    </Box>
  );
};

export default PageHeading;
