import React from "react";
import { NextSeo } from "next-seo";
import Head from "next/head";
import { useRouter } from "next/router";
import { AppConfig } from "../utils/AppConfig";

type IMetaProps = {
  meta_title: string;
  meta_description: string;
  exclude_from_sitemap: boolean;
  no_follow: boolean;
  no_index: boolean;
};

const NewMeta = () => {
  return <h1>Testing hero</h1>;
};

// export default NewMeta;

const Meta = ({
  meta_title,
  meta_description,
  exclude_from_sitemap,
  no_follow,
  no_index,
}: IMetaProps) => {
  const router = useRouter();

  return (
    <>
      <Head>
        <meta charSet="UTF-8" key="charset" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
          key="viewport"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${router.basePath}/apple-touch-icon.png`}
          key="apple"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`${router.basePath}/favicon-32x32.png`}
          key="icon32"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`${router.basePath}/favicon-16x16.png`}
          key="icon16"
        />
        <link rel="manifest" href={`${router.basePath}/site.webmanifest`} />
        <link
          rel="mask-icon"
          href={`${router.basePath}/safari-pinned-tab.svg`}
          color="#5bbad5"
        />
        <meta name="msapplication-TileColor" content="#2b5797" />
        <meta name="theme-color" content="#ffffff" />

        <link
          rel="icon"
          href={`${router.basePath}/favicon.png`}
          key="favicon"
        />
      </Head>
      <NextSeo
        title={`${meta_title ? meta_title + " | " : ""}${AppConfig.site_name}`}
        description={meta_description}
        openGraph={{
          title: meta_title,
          description: meta_description,
          images: [
            {
              url: router.basePath + "/site-meta-image.jpg",
              width: 1200,
              height: 630,
              alt: `${AppConfig.site_name}`,
              type: "image/jpeg",
            },
          ],
          site_name: AppConfig.site_name,
        }}
        noindex={no_index}
        nofollow={no_follow}
      />
    </>
  );
};

export default Meta;
