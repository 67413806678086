import React from "react";

const addLineBreaks = (text) =>
  text.split("\n").map((line, index) => (
    <React.Fragment key={index}>
      {line}
      {index !== text.split("\n").length - 1 && <br />} {/* Add <br/> except for the last line */}
    </React.Fragment>
  ));

export default addLineBreaks;
