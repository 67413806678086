import { Header } from "./Header/Header";
import { Footer } from "./Footer/Footer";

export function Layout({ navigation, settings, children, headerStyle, logoStyle }) {
  return (
    <div className="text-slate-800">
      <Header navigation={navigation} settings={settings} headerStyle={headerStyle} logoStyle={logoStyle} />
      <main>{children}</main>
      <Footer navigation={navigation} settings={settings} />
    </div>
  );
}
