import React from "react";
import styles from "./Footer.module.css";
import * as prismic from "@prismicio/client";
import { PrismicText } from "@prismicio/react";
import { PrismicNextLink } from "@prismicio/next";
import Link from "next/link";
import addLineBreaks from "@/functions/AddLineBreaks";
import { CheckIcon } from "@/icons/check-icon";
import { FacebookIcon } from "@/icons/facebook-icon";

import { Box, Flex, Heading, Spinner, Text } from "@chakra-ui/react";
import { InstagramIcon } from "@/icons/instagram-icon";

export const Footer = ({ navigation, settings }) => {
  return (
    <footer className={styles.footer}>
      <Box className={["container", styles.footer__container].join(" ")}>
        <Box className={styles.footerWrapper}>
          <Box
            className={[styles.footerColumn, styles.footerColumn1].join(" ")}
          >
            <ul className={styles.navList}>
              {navigation.data?.footer_links.map((item) => (
                <li
                  key={prismic.asText(item.label)}
                  className={[
                    styles.navItem,
                    item.ctaStyle ? "button" : null,
                  ].join(" ")}
                >
                  <PrismicNextLink field={item.link}>
                    <PrismicText field={item.label} />
                  </PrismicNextLink>
                </li>
              ))}
            </ul>
          </Box>
          <Box
            className={[styles.footerColumn, styles.footerColumn2].join(" ")}
          >
            <Flex
              alignItems="center"
              justifyContent="center"
              marginBottom="20px"
            >
              <Box marginRight="5px">
                <a
                  href={prismic.asLink(settings.data.facebook)}
                  className=""
                  target="_blank"
                  aria-label="Visit our Facebook page"
                  rel="noopener noreferrer"
                >
                  <FacebookIcon color="whites" fill="white" fontSize="20px" />
                </a>
              </Box>
              <Box marginLeft="5px">
                <a
                  href={prismic.asLink(settings.data.instagram)}
                  className=""
                  target="_blank"
                  aria-label="Visit our Instagram page"
                  rel="noopener noreferrer"
                >
                  <InstagramIcon color="whites" fill="white" fontSize="20px" />
                </a>
              </Box>
            </Flex>
            <Text
              textStyle="p"
              color="brand.dark"
              maxWidth="100%"
              marginBottom="5px"
              fontSize="14px"
            >
              &copy; ECA Glasgow. All Rights Reserved
            </Text>
            <Text
              textStyle="p"
              color="brand.dark"
              maxWidth="100%"
              marginBottom="0"
              fontSize="14px"
            >
              Website by{" "}
              <a
                href="https://www.digitalcodemedia.com"
                rel="noopener"
                target="_blank"
              >
                <b>Digital Code Media</b>
              </a>
            </Text>
          </Box>
          <Box
            className={[styles.footerColumn, styles.footerColumn3].join(" ")}
          >
            <Text textStyle="p" color="brand.dark" maxWidth="100%">
              {addLineBreaks(prismic.asText(settings.data.address))}
            </Text>

            <a
              href={`tel: ${prismic.asText(settings.data.phone_number)}`}
              className="linkUnderline"
            >
              <Text
                textStyle="p"
                color="brand.dark"
                maxWidth="100%"
                marginBottom="0"
              >
                {prismic.asText(settings.data.phone_number)}
              </Text>
            </a>
          </Box>
        </Box>
      </Box>
    </footer>
  );
};
